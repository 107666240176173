<template>
<!--  <svg :width="width || 22" :height="height || 22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--    <circle cx="11" cy="11" r="10.25" :stroke="fill || '#FAC300'" stroke-width="1.5"/>-->
<!--  </svg>-->
  <svg :width="width || 11" :height="height || 11" viewBox="0 0 4 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.924 0.199999H2.996L2.646 6.752H1.26L0.924 0.199999ZM1.96 10.098C1.63333 10.098 1.36267 9.99533 1.148 9.79C0.933333 9.57533 0.826 9.314 0.826 9.006C0.826 8.698 0.933333 8.44133 1.148 8.236C1.36267 8.03067 1.63333 7.928 1.96 7.928C2.27733 7.928 2.53867 8.03067 2.744 8.236C2.95867 8.44133 3.066 8.698 3.066 9.006C3.066 9.314 2.95867 9.57533 2.744 9.79C2.53867 9.99533 2.27733 10.098 1.96 10.098Z" fill="#FAC300"/>
  </svg>

</template>

<script>
import icon_config from "@/mixins/icon_config";

export default {
  name: "IconAttention",
  mixins: [icon_config],
}
</script>

<style scoped>

</style>
