<template>
  <app-grid-item
    style="cursor: pointer; position: relative; width: 300px"
    class="offers__grid__item"
    :class="{offers__grid__item_selected: isSelected, ['grid-shadow']: isMulti}"
    :item="offer"
    @click="clickToOffer"
  >
    <div
      class="offers__grid__item__header py-2"
      style=" position: relative;"
      :style="isMulti ? {'padding-bottom': '40px'} : {}"
    >
      <div
        v-if="parseInt(offer.unreadMessageNumber)"
        class="unread-message-badge"
      >
        {{ offer.unreadMessageNumber }}
      </div>
      <span style="position: relative">
        <IconEmployee />
        <sup
          style="
          position: absolute;top: -15px;
          color: rgb(139, 209, 24);
          border-radius: 50%;
          font-size: large;
          font-weight: bold;"
        > {{ offer.childrenNumber || '' }}</sup>
      </span>
      <span class="offers__grid__item__header__title">
        {{ offer.vacancy.localeTitle ? offer.vacancy.localeTitle : offer.vacancy.title }}
      </span>
      <IconOfferMulti
        v-if="isMulti"
        :width="40"
        :height="40"
      />
      <div
        v-if="isMulti"
        style="position: absolute;bottom: 5px; left: 0; right: 0;"
      >
        <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
          <div style="border: 1px solid #FAC300; width: 24px; height: 24px; border-radius: 50%; text-align: center">
            <IconAttention
              style="margin-top: 4px"
              :width="16"
              :height="16"
            />
          </div>
          <span style="font-size: 0.9em">Мульти-оффер:</span>
          <span style="font-size: 0.8em; color: rgba(0,0,0,0.6)">{{ `${offer.startDate} - ${offer.endDate}` }}</span>
        </div>
      </div>
    </div>

    <div class="offers__grid__item__period">
      <div class="offers__grid__item__period__date">
        <AppTextDateTime class="grid__item__date">
          <template #date>
            {{ $t('date.start') }}: {{ isMulti ? '' : offer.startDate }}
          </template>
          <template #time>
            {{ offer.startTime }}
          </template>
        </AppTextDateTime>

        <AppTextDateTime class="grid__item__date">
          <template #date>
            {{ $t('date.end') }}: {{ isMulti ? '' :offer.endDate }}
          </template>
          <template #time>
            {{ offer.endTime }}
          </template>
        </AppTextDateTime>
      </div>

      <AppBange>{{ offer.duration }}</AppBange>
    </div>

    <div class="offers__grid__item__info">
      <span class="offers__grid__item__info__text">
        {{ $t('offerList.card.responses') }}
        <span class="offers__grid__item__info__text__count">{{ offer.respondNumber || '' }}</span>
      </span>
      <span class="offers__grid__item__info__text">
        {{ $t('offerList.card.invite') }}
        <span class="offers__grid__item__info__text__count">{{ offer.invitationNumber || '' }}</span>
      </span>
    </div>

    <div
      v-if="!isMulti"
      class="offers__grid__item__bottom"
    >
      <span class="offers__grid__item__bottom__visit">
        {{ offer.viewNumber || '' }}
        <IconEye />
      </span>
    </div>

    <IconComplete
      v-if="isSelected"
      class="offers__grid__item__selected"
    />
  </app-grid-item>
</template>

<script>
import AppGridItem from '@/components/common/AppGridItem'
import IconEmployee from '@/components/common/icons/IconEmployee'
import IconAttention from '@/components/common/icons/IconAttention'
import { dateFormatApi, msToTime } from '@/utils/date_utils'
import IconEye from '@/components/common/icons/IconEye'
import IconComplete from '@/components/common/icons/IconComplete'
// import IconEmailActive from '@/components/common/icons/IconEmailActive'
// import IconEmail from '@/components/common/icons/IconEmail'
import AppTextDateTime from '@/components/common/AppTextDateTime'
import AppBange from '@/components/common/AppBange'
import taxMixin from '@/mixins/taxMixin.vue'
import IconOfferMulti from '@/components/common/icons/IconOfferMulti'

export default {
  name: 'WbdOfferGridItem',
  components: {
    AppBange, AppTextDateTime, IconOfferMulti, IconAttention,
    IconComplete,
    IconEye,
    IconEmployee, AppGridItem
  },
  mixins: [taxMixin],
  props: {
    offer: {
      type: Object,
      required: true
    },
    selectedOffer: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      startDate: null,
      endDate: null,
    }
  },
  computed: {
    isMulti () {
      return !!this.offer.isScheduler && this.offer.dayNumber && parseInt(`${this.offer.dayNumber}`) > 1
    },
    isSelected () {
      return this.selectedOffer.includes(this.offer.id)
    }
  },
  created () {
    this.startDate = dateFormatApi(this.offer.startDate, this.offer.startTime)
    this.endDate = dateFormatApi(this.offer.endDate, this.offer.endTime)
  },
  methods: {
    parseTime (millisecond) {
      return msToTime(millisecond, { hourShow: true })
    },
    clickToOffer () {
      this.$emit('click', this.offer)
    }
  }
}
</script>

<style lang="sass" scoped>
$default_padding-bottom: 10px

.grid-shadow
  -webkit-box-shadow: 8px -8px 8px 4px #7A7A7A
  -moz-box-shadow: 8px -8px 8px 4px #7A7A7A
  box-shadow: 8px -8px 8px 4px #7A7A7A

.offers__grid__item
  &__selected
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 1000
  &__header
    display: flex
    align-items: center
    padding-bottom: 15px
    border-bottom: $default_border_bottom

    &__icon
      flex-shrink: 0

    &__title
      @extend %header-2-type
      white-space: nowrap
      text-overflow: ellipsis
      margin-left: 10px
      overflow: hidden

  &__period
    margin-top: $default_padding-bottom
    display: flex
    padding-bottom: $default_padding-bottom
    border-bottom: $default_border_bottom

    &__date
      display: flex
      flex-direction: column

    &__date__text
      @extend %date-text
      color: rgba(0, 0, 0, 0.6)
      &:first-of-type
        margin-bottom: 8px
    &__time__text
      @extend %date-text
      font-weight: bold
    &__time
      display: flex
      align-items: center
      justify-content: center
      margin-left: auto
      background-color: rgba(0, 0, 0, 0.07)
      width: 62px
      height: 42px
      font-family: $mainFont
      font-style: normal
      font-weight: 800
      font-size: 20px
      line-height: 24px
      text-align: center
      border-radius: 6px

  &__info
    display: flex
    margin-top: $default_padding-bottom
    padding-bottom: $default_padding-bottom
    border-bottom: $default_border_bottom
    &__text
      @extend %navigation__children-item
      color: rgba(0, 0, 0, 0.8)
      &:last-of-type
        margin-left: auto
      &__count
        color: #A7A7A7
        @extend %navigation__children-item

  &__bottom
    display: flex
    margin-top: 10px

    &__price-full
      @extend %input-text
      color: rgba(0, 0, 0, 0.91)
      margin-right: 20px
      font-weight: 800
    &__price
      @extend %input-text
      color: rgba(0, 0, 0, 0.2)
      font-weight: 800
    &__visit
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 17px
      margin-left: auto
      color: rgba(0, 0, 0, 0.21)
    &__email
      position: relative
      margin-left: 10px

</style>
