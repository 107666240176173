<template>
  <svg :width="width ? width : 18" :height="height ? height : 18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.03076 0V0.832489H18.4399V15.0045H19.533V0H7.03076Z" fill="#8BD118"/>
    <path d="M4.84375 2.49853H16.252V16.6697H17.3449V1.66467H4.84375V2.49853Z" :fill="fill ? fill : '#8BD118'"/>
    <path d="M2.65503 4.16446H14.0631V18.3348H15.1564V3.33075H2.65503V4.16446Z" :fill="fill ? fill : '#8BD118'"/>
    <path d="M0.466797 20H12.9694V4.99677H0.466797V20ZM3.14444 8.73048H9.5893V9.25501H3.14444V8.73048ZM3.14444 11.0668H8.10892V11.5926H3.14444V11.0668ZM3.14444 13.4027H8.10892V13.9293H3.14444V13.4027ZM3.14444 15.7413H9.5893V16.2664H3.14444V15.7413Z"  :fill="fill ? fill : '#8BD118'"/>
  </svg>

</template>

<script>
  import icon_config from "@/mixins/icon_config";

  export default {
    name: "IconOfferMulti",
    mixins: [icon_config],
  }
</script>

<style lang="sass" scoped>
.icon
 cursor: pointer
.modal__header__close
  margin-left: auto
  cursor: pointer

.notification__icon-close
  margin-left: auto
</style>
