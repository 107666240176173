<template>
  <div
    class="grid-item"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppGridItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
.grid-item
  display: flex
  flex-direction: column
  background: #FFFFFF
  border: 1.5px solid rgba(0, 0, 0, 0.1)
  box-sizing: border-box
  box-shadow: 0px 5px 15px rgba(117, 117, 117, 0.15)
  border-radius: 6px

.offers__grid__item
  width: 30%
  margin: 1.5%
  height: 248px
  // margin: 15px 10px
  margin-top: 15px
  padding: 25px
  position: relative
  &_selected
    &:after
      content: ''
      position: absolute
      z-index: 5
      width: 100%
      height: 100%
      top: 0
      left: 0
      background-color: rgba(255, 255, 255, 0.7)
      border: 2px solid #8BD118

  &:hover .offers__grid__item__header__title
    color: rgba(116, 177, 14, 0.90)

.vacancies__grid__item
  width: 400px
  height: 325px
  // margin: 15px 10px
  margin-top: 15px
  padding: 25px
  position: relative
  &_selected
    &:after
      content: ''
      position: absolute
      z-index: 5
      width: 100%
      height: 100%
      top: 0
      left: 0
      background-color: rgba(255, 255, 255, 0.7)
      border: 2px solid #8BD118

  &:hover .offers__grid__item__header__title,
  &:hover .vacancies__grid__item__header__title
    color: rgba(116, 177, 14, 0.90)

.shift__grid__item
  display: flex
  flex-direction: column
  width: 455px
  height: 430px
  padding: 25px
  margin-top: 15px
  // margin: 15px
</style>
